import React from "react";
import { Button, Row, Col, Form, Modal, Spin, Space, Select } from 'antd';
import { getAllEmployee } from "services/employee-service";
import PropTypes from "prop-types";

const BenefitManagerScreen = (props) => {
    const [employees, setEmployees] = React.useState([]);
    const { show, loading, onCancel, onSave, row, company } = props;
    const [form] = Form.useForm();


    const onSaveManager = (values) => {
        onSave({ ...values, manager: employees.find(x => x.id === values.manager) })
    }

    const load = async () => {
        setEmployees(await getAllEmployee(company));
    }

    React.useEffect(() => {
        if (row) {
            form.setFieldsValue({
                manager: row.manager ? row.manager.id : null,
                benefit: row.benefit
            })
        }
    }, [row, form])

    React.useEffect(() => {
        load()
    }, [company])

    return (
        <Modal
            open={show}
            title="Gerente"
            onOk={() => { }}
            onCancel={onCancel}
            footer={
                [
                    <Button disabled={loading} key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
                        Salvar
                    </Button>
                ]}
        >
            <Row>
                <Col span={24}>
                    <Form form={form} layout="vertical" name="basic" onFinish={onSaveManager}>
                        <Space direction="vertical" size="large">
                            <Spin spinning={loading}>
                                <Form.Item hidden name="benefit" />
                                <Form.Item
                                    label="Gerente Responsável"
                                    name="manager"
                                >
                                    <Select placeholder="Selecione um gerente" allowClear>
                                        {employees.map(x => <Select.Option value={x.id}>{x.name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Spin>
                        </Space>
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
}

BenefitManagerScreen.propTypes = {
    show: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    row: PropTypes.any.isRequired,
    company: PropTypes.number.isRequired
};

export { BenefitManagerScreen };