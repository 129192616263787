import React from "react";
import { Layout, Row, Col, Card, Button, Select, Typography, Form, Table } from 'antd';
import { useOutletContext } from 'react-router-dom';
import { AutoCompleteUser, FormatMoney } from "components";
import { getCompanyBenefits } from "services/company-service";
import { getAllEmployee } from "services/employee-service";
import { getReportUrlsFotobase, getFotobase } from "services/report-service";
import { translate } from 'config/language';

const { Footer, Content } = Layout;

const generateDates = () => {
    var dates = [{ code: '', name: "Data" }]
    var d = new Date();
    while (d.getMonth() != 5 || d.getFullYear() != 2020) {
        dates.splice(1, 0, { code: d.getFullYear() + '-' + ((d.getMonth() + 1) > 9 ? (d.getMonth() + 1) : ("0" + (d.getMonth() + 1))), name: d.getFullYear() + '-' + ((d.getMonth() + 1) > 9 ? (d.getMonth() + 1) : ("0" + (d.getMonth() + 1))) })
        d.setMonth(d.getMonth() - 1);
    }
    return dates
}

const DataFetchFilters = {
    date: generateDates(),
    enabled: [
        { code: '', name: "Ativo/Inativo" },
        { code: true, name: "Ativo" },
        { code: false, name: "Inativo" }
    ],
    age: [
        { id: '', name: "" },
        { id: '1', name: "< 17" },
        { id: '2', name: "18 - 24" },
        { id: '3', name: "25 - 34" },
        { id: '4', name: "35 - 44" },
        { id: '5', name: "45 - 54" },
        { id: '6', name: "55 - 64" },
        { id: '7', name: "> 65" }
    ],
    state: [
        { code: '', name: 'Estado' },
        { code: 'AC', name: 'Acre' },
        { code: 'AL', name: 'Alagoas' },
        { code: 'AP', name: 'Amapá' },
        { code: 'AM', name: 'Amazonas' },
        { code: 'BA', name: 'Bahia' },
        { code: 'CE', name: 'Ceará' },
        { code: 'DF', name: 'Distrito Federal' },
        { code: 'ES', name: 'Espírito Santo' },
        { code: 'GO', name: 'Goiás' },
        { code: 'MA', name: 'Maranhão' },
        { code: 'MT', name: 'Mato Grosso"' },
        { code: 'MS', name: 'Mato Grosso do Sul' },
        { code: 'MG', name: 'Minas Gerais' },
        { code: 'PA', name: 'Pará' },
        { code: 'PB', name: 'Paraíba' },
        { code: 'PR', name: 'Paraná' },
        { code: 'PE', name: 'Pernambuco' },
        { code: 'PI', name: 'Piauí' },
        { code: 'RJ', name: 'Rio de Janeiro' },
        { code: 'RN', name: 'Rio Grande do Norte' },
        { code: 'RS', name: 'Rio Grande do Sul' },
        { code: 'RO', name: 'Rondônia' },
        { code: 'RR', name: 'Roraima' },
        { code: 'SC', name: 'Santa Catarina' },
        { code: 'SP', name: 'São Paulo' },
        { code: 'SE', name: 'Sergipe' },
        { code: 'TO', name: 'Tocantins' },
    ]
}

const DataFetchConfig = {
    dimensions: [
        { code: "employee", name: "Colaboradores", order: "name" },
        { code: "date", name: "Data", order: "name" },
        { code: "age", name: "Idade", order: "code" },
        { code: "state", name: "Estado", order: "name" },
        { code: "benefit", name: "Benefício", order: "name" },
        { code: "enabled", name: "Ativo/Inativo", order: "name" }
    ],
    metrics: [
        { code: "expense", name: "Gastos", unit: "R$ " }
    ],
    views: ["employee"],
    filters: ["employee", "date"]
};

const DataFetch = {
    data: [],
    loading: false
}

const FotobaseScreen = () => {
    const { company } = useOutletContext();
    const [filters, setFilters] = React.useState(DataFetchFilters)
    const [config, setConfig] = React.useState(DataFetchConfig)
    const [data, setData] = React.useState(DataFetch)
    const [benefits, setBenefits] = React.useState([])
    const [summary, setSummary] = React.useState({})
    const [equips, setEquips] = React.useState([])
    const [showClear, setShowClear] = React.useState(false)
    const [url, setUrl] = React.useState(null)
    const [dimension, setDimension] = React.useState(config.dimensions[0].code)
    const [form] = Form.useForm();

    React.useEffect(() => {
        loadBenefits()
        loadEquips()
        loadUrl()
    }, [])

    React.useEffect(() => {
        if (url)
            load()
    }, [url, company])

    const loadBenefits = async () => {
        const benefitsResponse = await getCompanyBenefits()
        setBenefits(benefitsResponse)
    }

    const loadEquips = async () => {
        const equipsResponse = await getAllEmployee(null, false, true)
        setEquips(equipsResponse)
    }

    const loadUrl = async () => {
        const report = await getReportUrlsFotobase();
        setUrl(report.urls.reportFotobase)
    }

    const onFilter = async (values) => {
        setShowClear(true)
        load(values)
    }

    const createFilter = (values) => {
        let filter = []
        if (values && values.date && values.date != '')
            filter.push({ filter: 'date', value: values.date });
        if (values && values.employee && values.employee != '')
            filter.push({ filter: 'employee', value: values.employee });
        if (values && values.age && values.age != '')
            filter.push({ filter: 'age', value: values.age });
        if (values && values.state && values.state != '')
            filter.push({ filter: 'state', value: values.state });
        if (values && values.benefit && values.benefit != '')
            filter.push({ filter: 'benefit', value: values.benefit });
        if (values && values.team && values.team != '')
            filter.push({ filter: 'team', value: values.team });
        if (values && (values.enabled === true || values.enabled === false))
            filter.push({ filter: 'enabled', value: values.enabled });
        return filter
    }

    const load = async (values) => {
        setData((x) => ({ ...x, loading: true }));
        const data = await getFotobase(
            url,
            company,
            config.dimensions.filter(x => x.code == form.getFieldValue("dimension"))[0],
            createFilter(values))
        setData((x) => ({ data: data.lines, loading: false }));
        setSummary(data.summary)
    }

    return (
        <React.Fragment>
            <Content>
                <Row>
                    <Col sm={24}>
                        <Form form={form} name="basic" onFinish={onFilter}>
                            <Card
                                bordered={false}
                            >
                                <Row justify="space-between" style={{ marginBottom: 15 }}>
                                    <Typography.Text strong>Filtros</Typography.Text>
                                    {
                                        showClear &&
                                        <Button size="small" type={"link"} onClick={() => {
                                            form.resetFields()
                                            setShowClear(false)
                                            load()
                                        }}>LIMPAR FILTROS</Button>
                                    }
                                </Row>
                                <Row gutter={20}>
                                    <Col lg={4} xs={24}>
                                        <Form.Item
                                            name="date"
                                        >
                                            <Select style={{ width: '100%' }} placeholder="Data" onChange={() => form.submit()}>
                                                {
                                                    filters.date.map((d) => <Select.Option value={d.code}>{d.name}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={4} xs={24}>
                                        <Form.Item
                                            name="employee"
                                        >
                                            <AutoCompleteUser onChange={(value) => {
                                                form.setFieldsValue({
                                                    "employee": value,
                                                });
                                                form.submit()
                                            }} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={4} xs={24}>
                                        <Form.Item
                                            name="enabled"
                                        >
                                            <Select style={{ width: '100%' }} placeholder="Ativo/Inativo" onChange={() => form.submit()}>
                                                {
                                                    filters.enabled.map((d) => <Select.Option value={d.code}>{d.name}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={4} xs={24}>
                                        <Form.Item
                                            name="age"
                                        >
                                            <Select style={{ width: '100%' }} placeholder="Idade" onChange={() => form.submit()}>
                                                {
                                                    filters.age.map((d) => <Select.Option value={d.id}>{d.name}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={4} xs={24}>
                                        <Form.Item
                                            name="state"
                                        >
                                            <Select style={{ width: '100%' }} placeholder="Estado" onChange={() => form.submit()}>
                                                {
                                                    filters.state.map((d) => <Select.Option value={d.code}>{d.name}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={4} xs={24}>
                                        <Form.Item
                                            name="team"
                                        >
                                            <Select style={{ width: '100%' }} placeholder="Equipe" onChange={() => form.submit()}>
                                                <Select.Option value="">Equipe</Select.Option>
                                                {
                                                    equips.map((d) => <Select.Option value={d.id}>{d.name}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={4} xs={24}>
                                        <Form.Item
                                            name="benefit"
                                        >
                                            <Select style={{ width: '100%' }} placeholder="Benefício" onChange={() => form.submit()}>
                                                <Select.Option value="">Benefício</Select.Option>
                                                {
                                                    benefits.map((d) => <Select.Option value={d.benefit}>{d.name}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Table scroll={{ y: 650, x: 1000 }} dataSource={data.data} loading={data.loading} pagination={false} bordered
                                    rowClassName={(record, index) => index % 2 === 0 ? '' : 'table-row-dark'}
                                    summary={(pageData) => {
                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0}><Typography.Text strong>Total: {summary.total}</Typography.Text></Table.Summary.Cell>
                                                    <Table.Summary.Cell align={"right"} width={200} index={1}><FormatMoney value={summary.balance} /></Table.Summary.Cell>
                                                    <Table.Summary.Cell align={"right"} width={200} index={2}><FormatMoney value={summary.expense} /></Table.Summary.Cell>
                                                    <Table.Summary.Cell align={"right"} width={200} index={2}><FormatMoney value={summary.ticket} /></Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </>
                                        );
                                    }}
                                >
                                    <Table.Column dataIndex="name" key="name"
                                        title={() =>
                                            <Form.Item
                                                name="dimension"
                                                initialValue={"employee"}
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Select style={{ width: 200 }} onChange={() => form.submit()}>
                                                    <Select.Option value="employee">Colaboradores</Select.Option>
                                                    <Select.Option value="date">Data</Select.Option>
                                                    <Select.Option value="age">Idade</Select.Option>
                                                    <Select.Option value="state">Estado</Select.Option>
                                                    <Select.Option value="benefit">Benefício</Select.Option>
                                                    <Select.Option value="enabled">Ativo/Inativo</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        }
                                    />
                                    <Table.Column align={"right"} width={200} title={translate('balance')} dataIndex="balance" key="balance" render={(value) => <FormatMoney value={value} />} />
                                    <Table.Column align={"right"} width={200} title="Gasto" dataIndex="expense" key="expense" render={(value) => <FormatMoney value={value} />} />
                                    <Table.Column align={"right"} width={200} title="Ticket Médio" dataIndex="ticket" key="ticket" render={(value) => <FormatMoney value={value} />} />
                                </Table>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Content>
        </React.Fragment>
    )
}

export default FotobaseScreen;