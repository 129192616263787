import axios from "axios";
import { handlerError } from "utils";

export const getAllEmployee = async (
  company = null,
  businessUnits,
  onlyBusinessUnits = false
) => {
  try {
    const { data } = await axios.get(`/api/employee`, {
      params: { order: "name", ...(company ? { company: company } : {}) }
    });
    if (onlyBusinessUnits) {
      return data.businessUnits ? data.businessUnits : [];
    } else {
      return data.employees
        ? businessUnits
          ? data
          : data.employees
        : businessUnits
          ? null
          : [];
    }
  } catch (ex) {
    handlerError(ex);
    return businessUnits ? null : [];
  }
};

export const getEmployee = async (user) => {
  try {
    const { data } = await axios.get(`/api/user/${user}`, {});
    return data.data ? data.data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getEmployeeBalance = async (user) => {
  try {
    const { data } = await axios.get(`/api/user/${user}/balance`, {});
    return data.details ? data.details : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getEmployeeBalanceByBenefit = async () => {
  try {
    const { data } = await axios.get(`/api/employee/balance`, {});
    return data.details ? data.details : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getAdvantages = async (user) => {
  try {
    const { data } = await axios.get(
      `/api/employee/advantages?user=${user}`,
      {}
    );
    return data ? data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getStatusAdvantage = async (url) => {
  try {
    const { data } = await axios.get(url, {});
    return data ? data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const activeAdvantage = async (advantage, user) => {
  try {
    const { data } = await axios.post("/api/employee/advantage/active", {
      advantage: advantage,
      user: user
    });
    return data ? data : {};
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};
