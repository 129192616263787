import PromisePool from "@supercharge/promise-pool/dist";
import moment from "moment";

import { formatDate, formatDecimalNumber, saveFileResume } from "utils";
import { getExpensesByCompanyIdAndPeriod } from "./expense-service";

export const createExpenseReport = async (reportData) => {
  const xlsxData = mapExpenseToSheetDataFormat(reportData);
  saveFileResume(xlsxData, "Comprovantes_de_Gastos", "Comprovantes_de_Gastos");
};

function calculateDaysDifference(startDate, endDate) {
  if (!startDate || !endDate) return "";
  const formattedStartDate = moment(startDate, "YYYY-MM-DD");
  const formattedEndDate = moment(endDate, "YYYY-MM-DD");
  return formattedEndDate.diff(formattedStartDate, "days");
}

function mapExpenseToSheetDataFormat(expenses) {
  return expenses.map((expense) => {
    return {
      Empresa: expense.companyName,
      "Data da Transação": expense.transactionAt
        ? formatDate(expense.transactionAt, "DD/MM/YYYY HH:mm:ss")
        : "",
      "Data da Criação do Comprovante": expense.createdAt
        ? formatDate(expense.createdAt, "DD/MM/YYYY HH:mm:ss")
        : "",
      Colaborador: expense.employeeName,
      "Empresa(s)": expense.expenseGroupCompany
        .map(
          (expCompany) =>
            `${expCompany.companyName} (${expCompany.companyDocument})`
        )
        .join(", "),
      "Saldo Utilizado": expense.balanceUsed,
      "Tipo de Gasto": expense.expenseType,
      Descrição: expense.merchantName,
      Status: expense.status,
      "Valor (R$)": expense.amount
        ? formatDecimalNumber(expense.amount / 100, 2)
        : 0,
      "Quilometragem (km)": expense.mileage
        ? `${formatDecimalNumber(expense.mileage, 0)}`
        : 0,
      "Litros (L)": expense.litersFuel
        ? `${formatDecimalNumber(expense.litersFuel, 2)}`
        : 0,
      "Valor / Litro (R$)": expense.amountLitersFuel
        ? formatDecimalNumber(expense.amountLitersFuel, 2)
        : 0,
      Combustível: expense.fuelType,
      "Placa do Veículo": expense.vehicleLicensePlate,
      "Data de Início da Hospedagem": expense.hotelStartDate
        ? formatDate(expense.hotelStartDate, "DD/MM/YYYY")
        : "",
      "Data de Fim da Hospedagem": expense.hotelEndDate
        ? formatDate(expense.hotelEndDate, "DD/MM/YYYY")
        : "",
      "Quantidade de Diárias": calculateDaysDifference(
        expense.hotelStartDate,
        expense.hotelEndDate
      ),
      "Valor da Diária": expense.hotelDailyCost
        ? formatDecimalNumber(expense.hotelDailyCost / 100, 2)
        : "",
      "Dados do hotel": expense.hotelDetails ? expense.hotelDetails : "",

      Endereço: expense.street,
      CEP: expense.postalCode,
      Bairro: expense.neighborhood,
      Cidade: expense.city,
      Estado: expense.state,
      País: expense.country
    };
  });
}
export const getExpenseReportData = async (
  companiesData,
  startPeriod,
  endPeriod,
  getAllPeriod = false,
  benefits
) => {
  let { results: solicitationReport, errors } = await PromisePool.for(
    companiesData
  )
    .withConcurrency(3)
    .process(async ({ id, companyName }) => {
      let expenseData = await getExpensesByCompanyIdAndPeriod(
        id,
        startPeriod,
        endPeriod,
        getAllPeriod,
        benefits
      );

      return expenseData.length > 0
        ? expenseData.map((expense) => ({
            ...expense,
            companies:
              expense.expenseGroupCompany.length > 0
                ? expense.expenseGroupCompany
                    .map(({ companyName }) => companyName)
                    .sort()
                    .join(", ")
                : "",
            companyName
          }))
        : expenseData;
    });
  const hasError = errors.length > 0;
  if (hasError)
    throw new Error("Erro ao buscar relatório de comprovante de gastos.");
  solicitationReport = solicitationReport
    .flat()
    .sort(
      (solicitationA, solicitationB) => solicitationB.id - solicitationA.id
    );
  const hasNotFoundSolicitation = solicitationReport.length === 0;
  if (hasNotFoundSolicitation)
    throw new Error("Não foram encontrados dados para o período informado.");
  return solicitationReport;
};
