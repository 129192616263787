import axios from 'axios';
import { exportXls, formatMoney, formatDate } from "utils";
import { getPendingCharges, getPendingSolicitation } from "services/company-service";
import { getChargeLinesIN, getRefundLinesIN } from "services/solicitation-service";
import { getAllEmployee } from "services/employee-service"
import lodash from "lodash";
import { translate } from 'config/language';

export const pendingCharges = ({
    load: async () => {
        const res = await getPendingCharges();
        if (res) {
            return {
                title: "Cobranças Abertas",
                subtitle: "Estes são os valores que estão em aberto do custo por colaborador.",
                fee: res.reduce((x, y) => x + y.value, 0),
                columns: [
                    {
                        align: "center",
                        title: "Data",
                        key: "createdAt",
                        dataIndex: "createdAt",
                        details: {
                            type: "date",
                            format: "DD/MM/YYYY"
                        }
                    },
                    {
                        align: "center",
                        title: "Mês Referência",
                        key: "month",
                        dataIndex: "month",
                        details: {
                            type: "date",
                            format: "MM/YYYY"
                        }
                    },
                    {
                        title: "Empresa",
                        key: "company.name",
                        dataIndex: ["company", "name"],
                    },
                    {
                        align: "right",
                        title: "Valor Total",
                        key: "value",
                        dataIndex: "value",
                        details: {
                            type: "money"
                        }
                    },
                    {
                        align: "right",
                        title: "Valor Unitário",
                        key: "unitaryValue",
                        dataIndex: "unitaryValue",
                        details: {
                            type: "money"
                        }
                    },
                    {
                        align: "right",
                        title: "Quantidade",
                        key: "quantity",
                        dataIndex: "quantity",
                    },
                    {
                        align: "center",
                        title: "Status",
                        key: "status.name",
                        dataIndex: ["status", "name"],
                    },
                ],
                data: res
            };
        }
        return null;
    }
})

export const pendingSolicitation = ({
    load: async (company) => {
        const res = await getPendingSolicitation(company);
        if (res) {
            return {
                title: "Desconto Disponível",
                subtitle: `Estes são os pedidos que estão em aberto e utilizam ${translate('balance')} da empresa.`,
                columns: [
                    {
                        align: "center",
                        title: "Data",
                        key: "createdAt",
                        dataIndex: "createdAt",
                        details: {
                            type: "date",
                            format: "DD/MM/YYYY"
                        }
                    },
                    {
                        align: "center",
                        title: "Tipo",
                        key: "type.name",
                        dataIndex: ["type", "name"],
                    },
                    {
                        align: "right",
                        title: "Valor",
                        key: "value",
                        dataIndex: "value",
                        details: {
                            type: "money"
                        }
                    },
                    {
                        align: "right",
                        title: "Desconto Usado",
                        key: "discount",
                        dataIndex: "discount",
                        details: {
                            type: "money"
                        }
                    },
                    {
                        align: "center",
                        title: "Status",
                        key: "status.name",
                        dataIndex: ["status", "name"],
                    },
                ],
                data: res
            };
        }
        return null;
    }
})


export const downloadBankSlip = async (url) => {
    try {
        const { data } = await axios.get(url, { responseType: 'blob' });
        const urlPdf = window.URL.createObjectURL(data);
        window.open(urlPdf, "_blank");
    } catch (ex) {
        console.log(ex)
    }
}
