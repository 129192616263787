import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Layout, Typography, Row, Col, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { getAllCompanies } from "services/company-service";
import { Header, AutoCompleteCompany } from "components";
import * as balanceAction from "actions/balance-action";
import { useNavigate } from "react-router-dom";
import Env from "env";
import { bindActionCreators } from "redux";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        ...balanceAction
    }, dispatch);

const DataFetch = {
    data: [],
    loading: false
}

const CardTypeScreen = (props) => {
    const { login, getCompanyBalanceAction } = props;
    const navigate = useNavigate();
    const [company, setCompany] = React.useState(null);

    const onChangeCompany = (x) => {
        setCompany(x);
    }

    React.useEffect(() => {
        getCompanyBalanceAction();
    }, [])

    const getQueryString = () => {
        if (login.company.master) {
            return "?company=" + company
        } else {
            return ""
        }
    }

    const getRoutes = () => {
        let routes = [
            { label: "Envios", route: `/card/shipments` },
            { label: "Solicitações", route: `/card/solicitations` }
        ]
        if (login.company.master) {
            routes.push({ label: "Desbloqueio de Cartões", route: `/card/list` })
        }
        return routes
    }

    return <Layout className="alymente-layout">
        <Header
            title="Envio de Cartão"
            subtitle="Selecione as opções de envio abaixo"
            routes={
                getRoutes()
            }
            middle={
                login.company.master ? <AutoCompleteCompany value={company} onChange={onChangeCompany} /> : undefined
            }
        />
        <Content style={{ display: 'flex', alignItems: 'center' }}>
            {
                (!login.company.master || company) &&
                <div style={{ flex: 1 }}>
                    <Row justify="center" style={{ marginBottom: 20 }}>
                        <Typography.Text strong>Aqui você pode fazer um novo envio de cartões:</Typography.Text>
                    </Row>
                    <Row gutter={16} justify="center">
                        <Col xl={8} lg={11} md={24} sm={24}>
                            <Button block type="primary" onClick={() => navigate("/card/employee" + getQueryString())}>Enviar para a casa do Colaborador</Button>
                        </Col>
                        <Col xl={8} lg={11} md={24} sm={24}>
                            <Button block type="primary" onClick={() => navigate("/card/company/matriz" + getQueryString())}>Enviar para a Empresa</Button>
                        </Col>
                        <Col xl={8} lg={11} md={24} sm={24}>
                            <Button block type="primary" onClick={() => navigate("/card/company/other" + getQueryString())}>Enviar para outro Endereço</Button>
                        </Col>
                    </Row>
                </div>
            }
        </Content>
        <Footer>
        </Footer>
    </Layout>
}

export default connect(mapStateToProps, mapDispatchToProps)(CardTypeScreen);