import React from 'react';
import { connect } from 'react-redux';
import { Layout, Table, Button, Typography, Row, Col, Tooltip, Alert, Select, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { getAllEmployee } from 'services/employee-service';
import { Header, CardInfo, TableSearch } from 'components';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAllConfiguredVehicles } from 'services/vehicle-service';

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({});

const DataFetch = {
   data: {
      vehicles: [],
   },
   loading: false,
};

const SolicitationCarFleetScreen = (props) => {
   const navigate = useNavigate();
   const [state] = React.useState(useLocation().state);
   const [selectAll, setSelectAll] = React.useState(false);
   const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
   const [filterVehicles, setFilterVehicles] = React.useState([]);
   const [vehicles, setVehicles] = React.useState([]);
   const [showMessageAccount, setShowMessageAccount] = React.useState(false);
   const [data, setData] = React.useState(DataFetch);
   const benefit = state?.benefit || null;

   const load = async () => {
      setData((x) => ({ ...x, loading: true }));
      const res = await getAllConfiguredVehicles();
      const filter = res.filter((x) => x.enabled);
      setVehicles(filter);
      setData({ data: { vehicles: res }, loading: false });
   };

   const onContinue = () => {
      if (selectedRowKeys.length === 0) {
         notification.error({ message: 'Selecione ao menos um colaborador.' });
      } else {
         navigate(`save`, {
            state: {
               benefit: benefit,
               vehicles: vehicles.filter((x) => selectedRowKeys.indexOf(x.id) > -1),
               carFleet: true,
            },
         });
      }
   };

   const verifyVehiclesAccount = () => {
      let length = data.data.vehicles.filter((u) => u.account == null).length;
      setShowMessageAccount(length > 0);
   };

   React.useEffect(() => {
      verifyVehiclesAccount();
   }, [data]);

   React.useEffect(() => {
      load();
   }, []);

   React.useEffect(() => {
      let rows = selectAll ? vehicles.filter((d) => d.account != null).map((d) => d.id) : [];
      setSelectedRowKeys(rows);
   }, [selectAll]);

   return (
      <Layout className="alymente-layout">
         <Header title="Selecione os veículos" subtitle="Selecione os veículos para os quais você quer fazer o pedido de carga.">
            <Row>
               <Col lg={6} md={12}>
                  <CardInfo title="Selecionados" value={`${selectedRowKeys.length}/${vehicles.length}`} />
               </Col>
               <Col span={24}>
                  {showMessageAccount ? (
                     <Alert
                        message="Alguns veículos não podem receber carga pois não possuem conta ativa."
                        type={'warning'}
                        style={{ marginTop: 10 }}
                        showIcon
                     />
                  ) : null}
               </Col>
            </Row>
         </Header>
         <Content>
            <Table
               rowKey={'id'}
               rowSelection={{
                  selectedRowKeys: selectedRowKeys,
                  onChange: (items) => {
                     if (filterVehicles.length > 0) {
                        var newItems = [...selectedRowKeys];
                        for (let i of filterVehicles) {
                           if (items.filter((f) => f === i.id).length > 0 && selectedRowKeys.filter((s) => s === i.id).length === 0)
                              newItems.push(i.id);
                           else if (items.filter((f) => f === i.id).length === 0 && selectedRowKeys.filter((s) => s === i.id).length > 0) {
                              newItems.splice(
                                 newItems.findIndex((f) => f === i.id),
                                 1
                              );
                           }
                        }
                        setSelectedRowKeys(newItems);
                     } else setSelectedRowKeys(items);
                  },
                  getCheckboxProps: (d) => ({
                     disabled: d.account == null ? true : false,
                  }),
               }}
               title={() => (
                  <Row>
                     <Col xs={12}>
                        <Button style={{ marginRight: 10 }} size="small" type={'primary'} onClick={() => setSelectAll(!selectAll)}>
                           {selectAll ? 'Limpar Todos' : 'Selecionar Todos'}
                        </Button>
                        <Typography.Text>
                           Selecionados: {selectedRowKeys.length}/{data.data.length === 0 ? [data.solicitation].length : vehicles.length}
                        </Typography.Text>
                     </Col>
                     <Col xs={12}>
                        <TableSearch
                           data={vehicles}
                           filter={['name', 'licensePlate', 'code']}
                           filterNumber={false}
                           onSearch={(e, filtered) => {
                              setFilterVehicles(e);
                           }}
                        />
                     </Col>
                  </Row>
               )}
               pagination={{ defaultPageSize: 1000 }}
               scroll={{ y: 400 }}
               dataSource={filterVehicles.length > 0 ? filterVehicles : vehicles}
               loading={data.loading}
               bordered
            >
               <Table.Column width={150} title="Placa" dataIndex={'licensePlate'} />
               <Table.Column
                  title="Marca/Modelo"
                  dataIndex={'name'}
                  render={(value, row) => {
                     return value;
                  }}
               />
               <Table.Column width={150} title="Código" dataIndex="code" key="code" />
            </Table>
         </Content>
         <Footer>
            <Button className="btn-save" type="primary" icon={<SaveOutlined />} onClick={onContinue}>
               Continuar
            </Button>
         </Footer>
      </Layout>
   );
};

export default connect(mapStateToProps, {})(SolicitationCarFleetScreen);
